.home {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .home-landing {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary-text);
        flex-grow: 1;
    }
}